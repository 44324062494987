<template>
  <div class=" bg-base flex flex-col gap-5 rounded-lg border p-10">
    <div class=" flex items-center gap-2  pb-5">
      <button
        :class="!isCompany && 'btn-primary btn-active'"
        class=" btn  btn-wide  "
        @click="isCompany=false"
      >
        Applicant Eligibility
      </button>
      <button
        :class="isCompany && 'btn-primary btn-active'"
        class="btn  btn-wide"
        @click="isCompany=true"
      >
        Companies Eligibility
      </button>
    </div>
    <div v-if="isCompany">
      <article class="prose max-w-none ">
        <CompanyEligibility />
      </article>
    </div>
    <div v-else>
      <article class="prose max-w-none ">
        <ApplicantEligibility />
      </article>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import CompanyEligibility from "./_company_eligibility.md"
import ApplicantEligibility from "./_applicant_eligibility.md"

const isCompany = ref(false);

</script>