<template>
  <div class="mt-4 flex flex-col gap-[60px]">
    <div class="bg-base flex flex-wrap gap-8 rounded-lg border px-6 dark:border-base-content/30 sm:items-center  sm:justify-center">
      <div class="m-[80px] scale-150 rounded-lg  px-4">
        <iframe
          allow="autoplay; encrypted-media"
          allowfullscreen=""
          height="230"
          src="https://player.vimeo.com/video/511482798"
        />
      </div>
      <div class="flex flex-col justify-center gap-3 sm:p-10">
        <div class="font-serif text-5xl font-bold">
          Postdoc at a startup?
        </div>
        <p class="text-light my-6 text-2xl">
          Stipends starting at <b class="font-bold">$78,000</b> per year
        </p>
        <div class="flex flex-wrap  gap-5 ">
          <router-link
            class="btn btn-secondary btn-wide "
            to="/registration"
          >
            Start Application today
          </router-link>
          <router-link
            class="btn  btn-ghost btn-accent"
            to="/benefits"
          >
            Explore benefits
          </router-link>
        </div>
      </div>
    </div>

    <div class="gap-4 rounded-lg  p-10">
      <div class="flex flex-col justify-center gap-5">
        <div class="font-serif text-3xl font-bold">
          Research opportunities
        </div>
        <div class=" text-xl">
          At nearly 200 science & engineering startups across the U.S.
          Opportunities visible only to those Postdoc candidates who upload their resume
        </div>
      </div>
    </div>

    <div class="flex flex-wrap items-center gap-10 rounded-lg border-2  border-base-content/10 p-10 ">
      <div class="flex flex-1 flex-col gap-5">
        <div class="font-serif text-3xl font-bold">
          NSF supported startups begin here
        </div>
        <div class="text-light text-xl">
          SBIR/STTR company registration is now open!
        </div>
      </div>
      <div class="flex gap-2 ">
        <a
          class="btn btn-ghost btn-accent"
          href="/company"
        >
          Login
        </a>
        <a
          class="btn btn-secondary "
          href="/company"
        >
          Register here
        </a>
      </div>
    </div>

    <div class=" p-5">
      <div class="mb-5 font-serif text-3xl font-bold">
        Our Aim
      </div>
      <p class="text-lg font-light">
        Funded by the National Science Foundation, the Innovative Postdoctoral Entrepreneurial Research Fellowship
        (I-PERF) defines a new career pathway for entrepreneurial-minded doctorate degree holders in science and
        engineering (S&E).

        I-PERF addresses overlapping aims and directives of the NSF and the Small Business Administration (SBA) to:

        Accelerate innovation at small businesses that support federal government research needs.
        Broaden participation in small business innovative research and high-tech entrepreneurship.
        Provide non-academic opportunities to apply advanced knowledge under business constraints.
        Increase the number of high tech businesses started by under-served doctoral degree holders.
      </p>
    </div>

    <div class="rounded-lg  bg-base-200/50 p-10 text-center">
      <div class="flex  items-center justify-center gap-5">
        <div class="font-serif text-2xl font-bold">
          How Can We Help?
        </div>
        <div class="text-light text-xl">
          Email us at <b>help@i-perf.org</b>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>


</script>